<div class="justify-content-center row app-body height-vh"> 
        <div class="col-lg-10 col-md-10">
                <h2 class="font-weight-bold mt-5 migrate-title">Migrate Users to New Domain</h2>
                <div class="row mt-3">
                        <div class="col-lg-3 col-md-4 col-7 col-sm-8">
                                <span class="font-weight-bold">Search</span>
                                <div>
                                <mat-form-field style="width:100%; top: 13px;">
                                        <mat-label>Filter students</mat-label>
                                        <input matInput id="search-input" type="text" (keyup)='filterDatatable($event)'>
                                </mat-form-field>
                                </div>
                        </div>
                        <div class="col-lg-4 col-md-5 col-7 col-sm-8 offset-lg-2" id="domain-selection">
                                <span class="font-weight-bold">Select the new domain to migrate to</span>
                                <div>
                                <mat-form-field style="width:100%; top: 13px;" [matMenuTriggerFor]="menu">
                                        <mat-label>Select a domain</mat-label>
                                        <input matInput id="domain-selected" [ngModel]="selectedDomainName" readonly type="text">
                                </mat-form-field>
                                <mat-menu #menu="matMenu">
                                        <a (click)="$event.stopPropagation();$event.preventDefault();" style="text-decoration: none;">
                                                <mat-form-field style="width:100%;text-align: left;padding: 0 15px;">
                                                        <input matInput id="filter" [(ngModel)]="domainFilterText" #filter (keyup)="tree.treeModel.filterNodes(filter.value)" placeholder="Filter domains" type="text">
                                                </mat-form-field>
                                                <tree-root (activate)="onSelectingDomain($event)" (deactivate)="onUnSelectingDomain($event)" #tree [nodes]="allDomains" [options]="options"></tree-root>
                                        </a>
                                </mat-menu>
                                </div>
                                      
                        </div>
                        <div class="col-lg-2 offset-lg-1 col-md-3 col-5 col-sm-4 pull-right">
                                <button type="button" class="btn btn-primary top-40" style="top: 50%; transform: translateY(-50%);position: absolute;right: 15px;width: 150px;" (click)="migrateDomain()" [disabled]="selected.length == 0 || selectedDomain == ''" >Migrate</button>
                        </div>
                </div>
        </div>
        <div style="float:left;top: -15px;" class="col-lg-10 justify-content-center">
        <ngx-datatable
          style="top:25px;width: 100%;height: calc(100vh - 360px); margin-bottom: 60px;min-height:400px"
          class="material"
          [rows]="rows"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
                  [rowHeight]="40"
                  [scrollbarV]="true"
                  [scrollbarH]="true"
          [selected]="selected"
          [selectionType]="SelectionType.checkbox"
                  [selectAllRowsOnPage]="false"
                  (select)="onSelect($event)"
                  [limit] = "5"
                  [offset]="currentPage"
                  (page)="onChange($event)"
         >
          <ngx-datatable-column
            [width]="30"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            [headerCheckboxable]="true"
                        [checkboxable]="true"
                        [cellClass] = "getCellClass"
                        style="padding-top: 9px !important; padding-bottom: 9px !important;"
          >
          </ngx-datatable-column>
          <ngx-datatable-column name="UserID" prop="userId" [cellClass] = "getCellClass"></ngx-datatable-column>
          <ngx-datatable-column name="First Name" prop="firstname" [cellClass] = "getCellClass"></ngx-datatable-column>
          <ngx-datatable-column name="Last Name" prop="lastname" [cellClass] = "getCellClass"></ngx-datatable-column>
          <ngx-datatable-column name="Domain ID" prop="domainid" [cellClass] = "getCellClass"> </ngx-datatable-column>
          <ngx-datatable-column name="Email" prop="email" [cellClass] = "
          getCellClass"></ngx-datatable-column>
        </ngx-datatable>
      </div>
          <ngx-spinner></ngx-spinner>

</div>
