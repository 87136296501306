import {Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private router: Router,
    private spinner: NgxSpinnerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    // @ts-ignore
    return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
      console.log('inside handler', err);
      
      if (err.status === 401 || err.status === 403 || err.status === 500) {
        console.log('Got 401 or 403');
        this.spinner.hide();
        
        // sessionStorage.removeItem('token');
        this.router.navigate(['/login']);
      }
      console.log('All good. Just passing through');
      return next.handle(request);
    }));
  }
}
