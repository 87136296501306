<nav class="navbar navbar-expand-lg navbar-light fixed-top row" style="box-shadow: 0px 0px 5px 3px #0089C5;height: 50px; background-color: white;">
    <a class="navbar-brand nav-link app-logo ml-2 mr-1"><img src="../../assets/RedCometLogo.jpg" height="35" alt=""></a>
    <a class="app-name nav-link font-weight-bold" style="cursor:context-menu;" alt="">Custom Reports</a>
    <button class="navbar-toggler" *ngIf="isLoggedIn" type="button" data-toggle="collapse" (click)="isNavbarCollapsed = !isNavbarCollapsed" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse" [ngbCollapse]="isNavbarCollapsed" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto" >
        <li ngbDropdown class="nav-item" *ngIf="isLoggedIn" #reportsDrop="ngbDropdown" (mouseleave)="reportsDrop.close()">
          <a (click)="true" style="cursor: pointer;" (click)="changeReport(0)" class="nav-link app-text" id="dropdown-reports" ngbDropdownToggle (mouseenter)="reportsDrop.open()" >REPORTS</a>
          <div ngbDropdownMenu aria-labelledby="dropdown-reports">
             <a ngbDropdownItem style="cursor: pointer;" (click)="changeReport(1)" class="dropdownItem">User Login and Logout Times</a>
          </div>
        </li>
        <li ngbDropdown class="nav-item" *ngIf="isLoggedIn" #operationsDrop="ngbDropdown" (mouseleave)="operationsDrop.close()">
          <a (click)="true" href="/#/operations" class="nav-link app-text" id="dropdown-operations" ngbDropdownToggle (mouseenter)="operationsDrop.open()" >OPERATIONS</a>
          <div ngbDropdownMenu aria-labelledby="dropdown-operations">
             <a ngbDropdownItem href="/#/migrateusers" class="dropdownItem">Migrate Users to New Domain</a>
          </div>
        </li> 
      </ul>
      <ul class="navbar-nav ml-auto" style="height: 50px;">
        <li class="nav-item">
            <a class="nav-link app-text" href="/#/logout" tabindex="-1" aria-disabled="true" *ngIf="isLoggedIn" (click)="isNavbarCollapsed = !isNavbarCollapsed;logout();">LOGOUT</a>
        </li>
      </ul>
    </div>
  </nav>
