import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { FormBuilder, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import {ApiServiceService} from '../api-service.service';

interface LoggedInUser {
  'userid': string;
  'username': string;
  'firstname': string;
  'lastname': string;
  'email': string;
  'domainid': string;
  'domainname': string;
  'userspace': string;
  'token': string;
  'authenticationexpirationminutes': number;
}

interface LoginResponse {
   user: LoggedInUser;
}

interface LoginResponseWrapper {
  response: LoginResponse;
  code: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm;
  submitted: boolean;
  isLoggedIn: boolean;
  loginError = false;

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private http: HttpClient,
              private router: Router, private spinner: NgxSpinnerService, private apiService: ApiServiceService) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.submitted = false;
    this.isLoggedIn = false;
    this.apiService.setLoggedInStatus(false);
    this.apiService.isLoggedIn.subscribe((status) => {
      this.isLoggedIn = status;
    });
  }

  ngOnInit(): void {
    const token = sessionStorage.getItem('token');
    if (token && token.length > 0) {
      this.router.navigate(['/home']);
    }
  }

   private saveLoginInfo(loggedInUser: LoginResponseWrapper): void {
     sessionStorage.setItem('domainId', loggedInUser.response.user.domainid);
     sessionStorage.setItem('userId', loggedInUser.response.user.userid);
     sessionStorage.setItem('token', loggedInUser.response.user.token);
  }

  // tslint:disable-next-line:typedef
  onSubmit(loginData: any) {
    this.spinner.show();
    this.submitted = true;
    // loginData.password = 'developer123';
    // loginData.username = 'ar-redcomet/dev1';
    if (this.loginForm.invalid) {
      this.spinner.hide();
      return;
    }
    this.apiService.login(loginData).subscribe((data: LoginResponseWrapper) => this.saveLoginInfo(data),
        (error: any) => {
          // TODO: show error message
          this.loginError = true;
          this.spinner.hide();
        },
      () => {
      this.spinner.hide();
      this.loginError = false;
      this.apiService.setLoggedInStatus(true);
      this.router.navigate(['/home']);
    });
  }
}
