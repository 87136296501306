<div class="justify-content-center row app-body" [ngClass]="{'height-100': selectedReport.length !== 0, 'height-vh': selectedReport.length === 0}">
	<div class="col-lg-10 col-md-10">
		<div class="row mt-5">
			<div class="col-lg-3 col-md-12 report-selection" [ngClass]="{'centered': selectedReport.length === 0}">
				<span class="font-weight-bold">Choose a Report</span>
				<ng-select [items]="reports" bindLabel="name" bindValue="name" [(ngModel)]="selectedReport" placeholder="Select a report" (ngModelChange)="getUserList()">
				</ng-select>
			</div>
			<div class="col-lg-7 col-md-12" *ngIf="selectedReport.length !== 0">
				<span class="font-weight-bold">Select Date Range</span>
				<div class="row" >
					<form class="" style="margin-top: 6px; width: 100%;">
						<mat-form-field class="col-lg-12" floatLabel="never" for="daterange-input" style="font-weight: 500">
						  <input type="text" matInput id="daterange-input" #dateRange style="padding-bottom: 7px;"
							ngxDaterangepickerMd
							[(ngModel)]="selectedDateRange"
							[showCustomRangeLabel]="true"
							[alwaysShowCalendars]="true"
							[showRangeLabelOnInput]="false"
							(datesUpdated)="onDatesUpdate(dateRange.value)"
							[ranges]="ranges"
							[linkedCalendars]="false"
							placeholder="Select date range..."
              name="dateRange"/>
						</mat-form-field>
					  </form>
				</div>
			</div>
			<div class="col-lg-2" *ngIf="selectedReport.length !== 0">
				<button type="button" class="btn btn-primary top-40" style="top: 50%; transform: translateY(-50%);position: absolute;right: 15px;width: 150px;" (click)="generateReport()" [disabled]="selectedReport == '' || selected.length == 0" >Generate Report</button>
			</div>
		</div>
		<div class="row" *ngIf="selectedReport.length !== 0">
			<div class="col-lg-3 col-md-8 col-6 col-sm-8">
				<span class="font-weight-bold">Search</span>
				<!-- <input type="text" (keyup)='filterDatatable($event)'> -->
				<div>
				<mat-form-field style="width:100%">
					<mat-label>Filter students</mat-label>
					<input matInput type="text" (keyup)='filterDatatable($event)'>
				</mat-form-field>
			</div>
			</div>
		</div>
	</div>
	<div *ngIf="selectedReport.length !== 0" style="float:left;top: -15px;" class="col-lg-10 justify-content-center">
        <ngx-datatable
          style="top:25px;width: 100%;height: calc(100vh - 360px); margin-bottom: 60px;min-height:400px"
          class="material"
          [rows]="rows"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
		  [rowHeight]="40"
		  [scrollbarV]="true"
		  [scrollbarH]="true"
          [selected]="selected"
          [selectionType]="SelectionType.checkbox"
		  [selectAllRowsOnPage]="false"
		  (select)="onSelect($event)"
		  [limit] = "5"
		  [offset]="currentPage"
		  (page)="onChange($event)"

        >
          <ngx-datatable-column
            [width]="30"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            [headerCheckboxable]="true"
			[checkboxable]="true"
			[cellClass] = "getCellClass"
			style="padding-top: 9px !important; padding-bottom: 9px !important;"
          >
          </ngx-datatable-column>
          <ngx-datatable-column name="UserID" prop="userId" [cellClass] = "getCellClass"></ngx-datatable-column>
          <ngx-datatable-column name="First Name" prop="firstname" [cellClass] = "getCellClass"></ngx-datatable-column>
          <ngx-datatable-column name="Last Name" prop="lastname" [cellClass] = "getCellClass"></ngx-datatable-column>
          <ngx-datatable-column name="Domain ID" prop="domainid" [cellClass] = "getCellClass"> </ngx-datatable-column>
          <ngx-datatable-column name="Email" prop="email" [cellClass] = "getCellClass"></ngx-datatable-column>
        </ngx-datatable>
      </div>
	  <ngx-spinner></ngx-spinner>
</div>


