import { BrowserModule } from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {Router} from "@angular/router";
import {AuthInterceptorInterceptor} from './interceptor/auth-interceptor.interceptor';
import { NgxSpinnerService } from "ngx-spinner";
import {MatIconModule} from '@angular/material/icon';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { OperationsComponent } from './operations/operations.component';
import { MigrateusersComponent } from './migrateusers/migrateusers.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { TreeModule } from '@circlon/angular-tree-component';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
  declarations: [ 
    AppComponent,
    NavBarComponent,
    HomeComponent,
    FooterComponent,
    LoginComponent,
    OperationsComponent,
    MigrateusersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    NgSelectModule, 
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    MatInputModule,
    MatIconModule,
    SnotifyModule,
    TreeModule,
    MatFormFieldModule,
    MatMenuModule,
    MatButtonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (injector: Injector, router: Router, spinner: NgxSpinnerService) => {
        return new AuthInterceptorInterceptor(injector, router, spinner);
        },
      multi: true,
      deps: [Injector]
    },{provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
