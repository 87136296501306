import {Component, NgModule, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import {ApiServiceService} from '../api-service.service';
import {SnotifyService, SnotifyToast} from 'ng-snotify';

interface User {
  dateOfBirth: string;
  dateRegistered: string;
  doaminParentid: string;
  domainFlags: string;
  domainName: string;
  domainUserspace: string;
  domainid: string;
  email: string;
  enrollmentStatus: string;
  firstlogindate: string;
  firstname: string;
  flags: string;
  gender: string;
  grade: string;
  guid: string;
  lastlogindate: string;
  lastname: string;
  lastpasswordchangeddate: string;
  notes: string;
  parentEmail: string;
  personalneedspreferences: string;
  phoneNumber: string;
  reference: string;
  schoolContact: string;
  schoolName: string;
  source: string;
  useDescription: string;
  userCreationby: string;
  userCreationdate: string;
  userId: string;
  userModifiedby: string;
  userModifieddate: string;
  username: string;
  version: string;
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  private usersList: User[] = [];
  rows = [];
  selected: User[] = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  currentPage: number = 0;
  reports = [
      { id: 1, name: 'User Login and Logout times' }
  ];
  selectedReport = '';

  selectedDateRangeText = '';
  selectedDateRange: {startDate: moment.Moment, endDate: moment.Moment};
  alwaysShowCalendars: boolean;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  filteredData = [];
  columnsWithSearch : string[] = [];
  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') );
  }

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private http: HttpClient,
              private spinner: NgxSpinnerService, private apiService: ApiServiceService, private snotifyService: SnotifyService) {
                this.alwaysShowCalendars = true;
                this.selectedDateRange = {startDate: moment().subtract(6, 'days'), endDate: moment()};
               }

  ngOnInit(): void {
    console.log('getting initialized');
    const token = sessionStorage.getItem('token');
    if (token && token.length > 0) {
      this.apiService.setLoggedInStatus(true);
      const reportId = Number(this.activatedRoute.snapshot.paramMap.get('reportId'));
      if(reportId){
        this.selectedReport = this.reports[reportId-1].name;
        this.getUserList();
      }else{
        this.selectedReport = '';
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  getUserList(): any {
    this.spinner.show();
    if (this.selectedReport.length === 0) {
      this.rows = [];
      return;
    }
    const domainId = sessionStorage.getItem('domainId');
    const token = sessionStorage.getItem('token');
    const requestData = {

      agilixCriteriaRequestDTO: {
        exportNeeded: true,
        includedescendantdomains: true,
        limit: 3000,
        query: '/active=1',
        select: 'data(meta-DOB,meta-DateRegistered)',
        show: '',
        text: ''
      },
      authToken: token
    };
    this.apiService.listUsers(requestData, domainId).subscribe(
      (data: any) => {
        this.usersList = data;
        this.rows = data;
        this.apiService.setLoggedInStatus(true);
        this.filteredData = this.rows;
      },
      (error: any) => {
        this.spinner.hide();
        alert('Session expired! Please login again.');
        sessionStorage.setItem('domainId', '');
        sessionStorage.setItem('token', '');
        sessionStorage.setItem('userId', '');
        this.apiService.setLoggedInStatus(false);
        this.router.navigate(['/login']);
      },
      () => {
        this.spinner.hide();
      }
    );

  }

  getZipFile(data: any): any {
    const blob = new Blob([data], {
      type: 'application/zip'
    });
    this.spinner.hide();
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  generateLoginLogoutTimesReport(startDate: string, endDate: string, userIds: number[]): void {
    const requestData = {
      endDate,
      startDate,
      userIds,
      authToken: sessionStorage.getItem('token')
    };
    this.apiService.getLoginLogoutTimesReport(requestData).subscribe(
        (data: any) => {
          this.getZipFile(data);
          this.spinner.hide();
          console.log('done');
          this.snotifyService.success('Successfully downloaded.', {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          });
        },
        (error: any) => {/*TODO : Show error message*/
          this.spinner.hide();
          this.snotifyService.error('Downloading was unsuccessfull!', {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          });
          if(error.status == 500){
            alert('Session expired! Please login again.');
            sessionStorage.setItem('domainId', '');
            sessionStorage.setItem('userId', '');
            sessionStorage.setItem('token', '');
            this.apiService.setLoggedInStatus(false);
            this.router.navigate(['/login']);
          }
        });
  }

  generateReport(): void{
    const startDate = this.selectedDateRangeText !== '' ? moment(this.selectedDateRangeText.split('-')[0]).format('MM/DD/YYYY') : this.selectedDateRange.startDate.format('MM/DD/YYYY');
    const endDate = this.selectedDateRangeText !== '' ? moment(this.selectedDateRangeText.split('-')[1]).format('MM/DD/YYYY') : this.selectedDateRange.endDate.format('MM/DD/YYYY');
    const userIds = this.selected.map((user: any) => user.userId);
    this.spinner.show();
    this.generateLoginLogoutTimesReport(startDate, endDate, userIds);
  }

  onSelect({ selected }: {selected: User[]}): void {
    console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onDatesUpdate(value: any): void{
    this.selectedDateRangeText = value;
  }

  getCellClass() {
    return {
      'cell-ngx': true
    };
  }
  filterDatatable(event: any){
    let filter = event.target.value.toLowerCase();
    this.columnsWithSearch = ['userId', 'firstname', 'lastname', 'domainid', 'email'];
    // assign filtered matches to the active datatable
    this.rows = this.filteredData.filter(item => {
      // iterate through each row's column data
      for (let i = 0; i < this.columnsWithSearch.length; i++){
        var colValue: String = item[this.columnsWithSearch[i]] ;

        // if no filter OR colvalue is NOT null AND contains the given filter
        if (!filter || (!!colValue && colValue.toString().toLowerCase().indexOf(filter) !== -1)) {
          // found match, return true to add to result set
          return true;
        }
      }
    });
    this.currentPage = 0;
    setTimeout(function(){
      document.getElementsByClassName('datatable-body')[0].scrollTop = 1;
    },100)
  }

  onChange(event: any): void {
    this.currentPage = event.offset;
  }
}
