import { Component, OnInit } from '@angular/core';
import {ApiServiceService} from '../api-service.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  isLoggedIn = false;
  isNavbarCollapsed = true;

  constructor(private apiService: ApiServiceService, private router: Router) {
    this.apiService.isLoggedIn.subscribe((status) => {
      console.log('inside nav bar', status);
      this.isLoggedIn = status;
    });

  }

  ngOnInit(): void {
  }

  changeReport(id: Number){
    this.router.navigate(['/home', { reportId: id }]).then(() => {
      window.location.reload();
    });;
  }

  logout(): void {
    this.apiService.setLoggedInStatus(false);
    sessionStorage.removeItem('domainId');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('token');
  }
}
