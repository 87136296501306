import {Component, NgModule, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import {ApiServiceService} from '../api-service.service';
import {SnotifyService, SnotifyToast} from 'ng-snotify';
import { TreeComponent } from '@circlon/angular-tree-component';

interface User {
  dateOfBirth: string;
  dateRegistered: string;
  doaminParentid: string;
  domainFlags: string;
  domainName: string;
  domainUserspace: string;
  domainid: string;
  email: string;
  enrollmentStatus: string;
  firstlogindate: string;
  firstname: string;
  flags: string;
  gender: string;
  grade: string;
  guid: string;
  lastlogindate: string;
  lastname: string;
  lastpasswordchangeddate: string;
  notes: string;
  parentEmail: string;
  personalneedspreferences: string;
  phoneNumber: string;
  reference: string;
  schoolContact: string;
  schoolName: string;
  source: string;
  useDescription: string;
  userCreationby: string;
  userCreationdate: string;
  userId: string;
  userModifiedby: string;
  userModifieddate: string;
  username: string;
  version: string;
}

@Component({
  selector: 'app-migrateusers',
  templateUrl: './migrateusers.component.html',
  styleUrls: ['./migrateusers.component.scss']
})
export class MigrateusersComponent implements OnInit {
  
  @ViewChild(TreeComponent)
  private tree: TreeComponent;

  domains = [
      { id: 1, name: 'ABC School Domain 1' },
      { id: 2, name: 'PQR School Domain 2' }
  ];
  allDomains = [{}];
  options = {
    childrenField: 'childDomains'
  };
  selectedDomain = '';
  selectedDomainName = '';
  domainFilterText = '';

  private usersList: User[] = [];
  rows = [];
  selected: User[] = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  currentPage: number = 0;

  filteredData = [];
  columnsWithSearch : string[] = [];

  selfUserId :String = "";

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private http: HttpClient,
              private spinner: NgxSpinnerService, private apiService: ApiServiceService, private snotifyService: SnotifyService) {
               }

  ngOnInit(): void {
    console.log('Migrate Users getting initialized');
    const token = sessionStorage.getItem('token');
    this.selfUserId = String(sessionStorage.getItem('userId'));
    if (token && token.length > 0) {
      this.apiService.setLoggedInStatus(true);
    } else {
      this.router.navigate(['/login']);
    }
    this.getDomainList()
    this.getAllDomainList()
    this.getUserList();
  }

  getUserList(): any {
    this.spinner.show();

    const domainId = sessionStorage.getItem('domainId');
    const token = sessionStorage.getItem('token');
    const requestData = {

      agilixCriteriaRequestDTO: {
        exportNeeded: true,
        includedescendantdomains: true,
        limit: 3000,
        query: '/active=1',
        select: 'data(meta-DOB,meta-DateRegistered)',
        show: '',
        text: ''
      },
      authToken: token
    };
    this.apiService.listUsers(requestData, domainId).subscribe(
      (data: any) => {
        this.usersList = data;
        this.rows = data.filter((row: any) =>  {
          return row.userId !== this.selfUserId; 
        });
        this.apiService.setLoggedInStatus(true);
        this.filteredData = this.rows;
      },
      (error: any) => {
        this.spinner.hide();
        alert('Session expired! Please login again.');
        sessionStorage.setItem('domainId', '');
        sessionStorage.setItem('userId', '');
        sessionStorage.setItem('token', '');
        this.apiService.setLoggedInStatus(false);
        this.router.navigate(['/login']);
      },
      () => {
        this.spinner.hide();
      }
    );

  }

  getDomainList(): any {
    this.spinner.show();
    const domainId = sessionStorage.getItem('domainId');
    const token = sessionStorage.getItem('token');
    const requestData = {
      authToken: token
    };
    this.apiService.listSubDomains(requestData, domainId).subscribe(
      (data: any) => {
        this.domains = data.response.domains.domain.map((domain :any) => {
          return {name: domain.name, id: domain.id};
        });
      },
      (error: any) => {
        if(error.status == 500){
          alert('Session expired! Please login again.');
          sessionStorage.setItem('domainId', '');
          sessionStorage.setItem('userId', '');
          sessionStorage.setItem('token', '');
          this.apiService.setLoggedInStatus(false);
          this.router.navigate(['/login']);
        }
      },
      () => {
      }
    );

  }
  getAllDomainList(): any {
    const domainId = sessionStorage.getItem('domainId');
    const token = sessionStorage.getItem('token');
    const requestData = {
      authToken: token
    };
    this.apiService.listAllDomains(requestData, domainId).subscribe(
      (data: any) => {
        this.allDomains = [data];
      },
      (error: any) => {
        if(error.status == 500){
          alert('Session expired! Please login again.');
          sessionStorage.setItem('domainId', '');
          sessionStorage.setItem('userId', '');
          sessionStorage.setItem('token', '');
          this.apiService.setLoggedInStatus(false);
          this.router.navigate(['/login']);
        }
      },
      () => {
      }
    );

  }

  onSelectingDomain(event: any): any {
    this.selectedDomain = event.node.data.id;
    this.selectedDomainName = event.node.data.name;
  }

  onUnSelectingDomain(event: any): any {
    this.selectedDomain = '';
    this.selectedDomainName = '';
    setTimeout(() => {
      this.tree.treeModel.setFocusedNode(false);
    }, 100)
  }

  

  migrateDomain(): any {
    this.spinner.show();
    const domainId = sessionStorage.getItem('domainId');
    const token = sessionStorage.getItem('token');
    const selectedStudents = this.selected.map((student) => {
      return { userid: student.userId, domainid: this.selectedDomain }
    })
    const requestData = {
      selectedStudents,
      authToken: token
    };
    this.apiService.migrateDomain(selectedStudents, token).subscribe(
      (data: any) => {
        this.spinner.hide();
        (<HTMLInputElement>document.getElementById("search-input")).value = "";
        this.selected.splice(0, this.selected.length);
        this.selectedDomain = '';
        this.snotifyService.success('Successfully migrated.', {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        });
        this.tree.treeModel.setActiveNode(this.tree.treeModel.getActiveNode, false);
        this.domainFilterText = '';
        this.tree.treeModel.clearFilter(); 
        setTimeout(() => {
          this.tree.treeModel.setFocusedNode(false);
        }, 200)
        this.getUserList();
      },
      (error: any) => {
        this.spinner.hide();
        this.snotifyService.error('Migration failed!', {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        });
        if(error.status == 500){
          alert('Session expired! Please login again.');
          sessionStorage.setItem('domainId', '');
          sessionStorage.setItem('userId', '');
          sessionStorage.setItem('token', '');
          this.apiService.setLoggedInStatus(false);
          this.router.navigate(['/login']);
        }
      },
      () => {
      }
    );

  }

  onSelect({ selected }: {selected: User[]}): void {
    const len = this.selected.length;
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    /*if (this.selected[len-1].userId == this.selfUserId) {
       this.selected.splice(len-1, 1);
    }*/
    console.log('Select Event', selected, this.selected);
  }

  getCellClass() {
    return {
      'cell-ngx': true
    };
  }
  filterDatatable(event: any){
    let filter = event.target.value.toLowerCase();
    this.columnsWithSearch = ['userId', 'firstname', 'lastname', 'domainid', 'email'];
    // assign filtered matches to the active datatable
    this.rows = this.filteredData.filter(item => {
      // iterate through each row's column data
      for (let i = 0; i < this.columnsWithSearch.length; i++){
        var colValue: String = item[this.columnsWithSearch[i]] ;

        // if no filter OR colvalue is NOT null AND contains the given filter
        if (!filter || (!!colValue && colValue.toString().toLowerCase().indexOf(filter) !== -1)) {
          // found match, return true to add to result set
          return true;
        }
      }
    });
    this.currentPage = 0;
    setTimeout(function(){
      document.getElementsByClassName('datatable-body')[0].scrollTop = 1;
    },100)
  }

  onChange(event: any): void {
    this.currentPage = event.offset;
  }
}
