import {Component, NgModule, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ApiServiceService} from '../api-service.service';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {

  operations = [
      { id: 1, name: 'Migrate Users to New Domain' }
  ];
  selectedOperation = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private http: HttpClient, private apiService: ApiServiceService) {
               }

  ngOnInit(): void {
    console.log('Operations home getting initialized');
    const token = sessionStorage.getItem('token');
    if (token && token.length > 0) {
      this.apiService.setLoggedInStatus(true);
    } else {
      this.router.navigate(['/login']);
    }
  }

  operationSelected() {
    if (this.selectedOperation == 'Migrate Users to New Domain'){
       this.router.navigate(['/migrateusers']);
    }
  }

}
