import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  loginUrl = 'https://reports-redcomet.org:8440/redcomet_client/agilix/userLogin';
  loginLogoutTimesReportUrl = `https://reports-redcomet.org:8440/redcomet_client/userActivityReport`;
  listUsersUrl = 'https://reports-redcomet.org:8440/redcomet_client/agilix/listUsersByDomainId/';
  listSubDomainsUrl = 'https://reports-redcomet.org:8440/redcomet_client/agilix/listDomainsByParentDomainId/';
  listAllDomainsUrl = 'https://reports-redcomet.org:8440/redcomet_client/agilix/listAllDomains/';
  migrateDomainUrl = 'https://reports-redcomet.org:8440/redcomet_client/agilix/userMigrationToNewDomain/';
  isLoggedIn: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  login(loginData: any): any {
    const httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders.append('Access-Control-Allow-Origin', '*');
    httpHeaders.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    return this.http.post<any>(this.loginUrl, {
      request: {
        password: loginData.password,
        username: loginData.username,
        cmd: 'login2'
      }
    }, { headers: httpHeaders });
  }

  getLoginLogoutTimesReport(requestData: any): any {
    return this.http.post(this.loginLogoutTimesReportUrl, requestData, {responseType: 'arraybuffer'});
  }

  listUsers(requestData: any, domainId: string | null): any {
    return this.http.post(this.listUsersUrl + `${domainId}`, requestData);
  }

  listSubDomains(requestData: any, domainId: string | null): any {
    return this.http.post(this.listSubDomainsUrl + `${domainId}`, requestData);
  }
  listAllDomains(requestData: any, domainId: string | null): any {
    return this.http.post(this.listAllDomainsUrl + `${domainId}`, requestData);
  }

  setLoggedInStatus(isLoggedIn: boolean): void {
    this.isLoggedIn.next(isLoggedIn);
  }

  migrateDomain(requestData: any, token: string | null): any {
    return this.http.post(this.migrateDomainUrl + `${'?token='+token}`, requestData, {responseType: 'arraybuffer'});
  }
}
