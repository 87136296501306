<div class="container-fluid">
    <app-nav-bar></app-nav-bar>
    <div class="main-container">
        <router-outlet></router-outlet>
        <ng-snotify></ng-snotify>
    </div>
    <div class="footer mt-auto py-3 row justify-content-center" style="height: 35px; padding: 5px 0 !important;">
        <app-footer></app-footer>  
    </div>
</div>
