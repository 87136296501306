<div class="row justify-content-center app-body">
	<ngx-spinner></ngx-spinner>
       <div class="col-12 text-center justify-content-center main_content">
		<div class="row justify-content-center mt-5" style="margin-top: 11% !important;">
		<div class="align-self-center">
			<h1 class="font-weight-bold heading-text">Welcome to RedComet Custom Reports</h1>
			<br/>
			<p class="font-weight-bold" style="font-size: 18px; color: rgb(0,0,0,0.8) !important;">Login to run custom reports</p>
		</div>
		</div>
		<div class="row justify-content-center">
			<form id="loginForm" class="align-self-center" style="margin-top: 2% !important; width: 400px !important;" [formGroup]="loginForm" name="loginForm" (ngSubmit)="onSubmit(loginForm.value)" novalidate>
					<div style="color: red" *ngIf="loginError">Login failed. Please try again.</div>
					<div class="row form-group form-inline mt-4">
						<label for="username"  class="col-form-label col-md-3 col-3">Username</label>
						<input type="text" class="ml-2 form-control col-md-7 col-7" formControlName="username" name="username" ng-model="username" id="username" autofocus required>
						<div class="row offset-md-3 col-md-5">
							<small *ngIf="submitted && loginForm.invalid" class="form-text text-danger" >Username is required.</small>
						</div>
					</div>
					<div class="row form-group form-inline">
						<label for="passwd" class="col-form-label col-md-3 col-3">Password</label>
						<input type="password" name="password" class="ml-2 form-control col-md-7 col-7" formControlName="password" ng-model="password" id="passwd" required>
						<div class="row offset-md-3 col-md-5">
							<small *ngIf="submitted && loginForm.invalid" class="form-text text-danger" >Password is required.</small>
						</div>
					</div>
				<button type="submit" class="btn btn-primary mb-5" >Submit</button>
			</form>
		</div>
	</div>
</div>

