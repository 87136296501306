<div class="justify-content-center row app-body height-vh">
        <div class="col-lg-10 col-md-10">
                <div class="row mt-5">
                        <div class="col-lg-4 col-md-12 centered">
                                <span class="font-weight-bold">Choose an operation you would like to perform </span>
                                <ng-select [items]="operations" bindLabel="name" bindValue="name" [(ngModel)]="selectedOperation" placeholder="Select an operation" (ngModelChange)="operationSelected()"> 
                                </ng-select>
                        </div>
                </div>
        </div>
</div>
